// extracted by mini-css-extract-plugin
export const root = "header-module--root--3zu-a";
export const rootDarkMode = "header-module--rootDarkMode--3a2JE header-module--root--3zu-a";
export const wrapper = "header-module--wrapper--jg-oO";
export const branding = "header-module--branding--3quQ9";
export const brandingInner = "header-module--brandingInner--8mncL";
export const brandingLink = "header-module--brandingLink--2PKL1";
export const toggleNavButton = "header-module--toggleNavButton--3y0cM";
export const nav = "header-module--nav--2eVPq";
export const navDarkMode = "header-module--navDarkMode--3atqF header-module--nav--2eVPq";
export const navInner = "header-module--navInner--2BStC";
export const link = "header-module--link--vTkXk";
export const linkActive = "header-module--linkActive--2x1BK header-module--link--vTkXk";
export const toggleSwitchContainer = "header-module--toggleSwitchContainer--3H4-9";