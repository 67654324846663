import React from 'react';

const HomeIcon = (props) => (
  <svg viewBox='0 0 185 185' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M173.437 86.7187C172.233 86.7109 171.06 86.3269 170.084 85.6203L92.5 30.2359L14.9156 85.6203C13.6709 86.3575 12.1951 86.6012 10.7794 86.3034C9.36382 86.0056 8.11127 85.1879 7.26907 84.0118C6.42687 82.8356 6.05623 81.3864 6.2303 79.9503C6.40438 78.5142 7.11053 77.1956 8.20935 76.2547L89.1469 18.4422C90.126 17.745 91.298 17.3704 92.5 17.3704C93.7019 17.3704 94.874 17.745 95.8531 18.4422L176.791 76.2547C177.778 76.9692 178.514 77.9774 178.894 79.1353C179.273 80.2931 179.278 81.5413 178.906 82.7018C178.534 83.8622 177.805 84.8755 176.823 85.5969C175.841 86.3183 174.656 86.7109 173.437 86.7187Z'
      fill='currentColor'
    />
    <path
      d='M28.9062 52.0312C27.373 52.0312 25.9025 51.4222 24.8183 50.338C23.7341 49.2538 23.125 47.7833 23.125 46.25V23.125C23.125 21.5917 23.7341 20.1212 24.8183 19.037C25.9025 17.9528 27.373 17.3438 28.9062 17.3438H52.0312C53.5645 17.3438 55.035 17.9528 56.1192 19.037C57.2034 20.1212 57.8125 21.5917 57.8125 23.125C57.8125 24.6583 57.2034 26.1288 56.1192 27.213C55.035 28.2972 53.5645 28.9062 52.0312 28.9062H34.6875V46.25C34.6875 47.7833 34.0784 49.2538 32.9942 50.338C31.91 51.4222 30.4395 52.0312 28.9062 52.0312Z'
      fill='currentColor'
    />
    <path
      d='M144.531 167.656H115.625C114.092 167.656 112.621 167.047 111.537 165.963C110.453 164.879 109.844 163.408 109.844 161.875V121.406H75.1562V161.875C75.1562 163.408 74.5472 164.879 73.463 165.963C72.3788 167.047 70.9083 167.656 69.375 167.656H40.4688C35.8689 167.656 31.4575 165.829 28.2049 162.576C24.9523 159.324 23.125 154.912 23.125 150.312V92.5C23.125 90.9667 23.7341 89.4962 24.8183 88.412C25.9025 87.3278 27.373 86.7188 28.9062 86.7188C30.4395 86.7188 31.91 87.3278 32.9942 88.412C34.0784 89.4962 34.6875 90.9667 34.6875 92.5V150.312C34.6875 151.846 35.2966 153.316 36.3808 154.4C37.465 155.485 38.9355 156.094 40.4688 156.094H63.5938V115.625C63.5938 114.092 64.2028 112.621 65.287 111.537C66.3712 110.453 67.8417 109.844 69.375 109.844H115.625C117.158 109.844 118.629 110.453 119.713 111.537C120.797 112.621 121.406 114.092 121.406 115.625V156.094H144.531C146.065 156.094 147.535 155.485 148.619 154.4C149.703 153.316 150.312 151.846 150.312 150.312V92.5C150.312 90.9667 150.922 89.4962 152.006 88.412C153.09 87.3278 154.56 86.7188 156.094 86.7188C157.627 86.7188 159.098 87.3278 160.182 88.412C161.266 89.4962 161.875 90.9667 161.875 92.5V150.312C161.875 154.912 160.048 159.324 156.795 162.576C153.543 165.829 149.131 167.656 144.531 167.656V167.656Z'
      fill='currentColor'
    />
  </svg>
);

export default HomeIcon;
